<template>
  <BOverlay
    :show="loadingCard"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BRow>
      <BCard
        v-if="size(data) === 0"
        class="p-[5px]"
        body-class="card-dashed"
      >
        <BOverlay
          :show="loadingPin || loadingBalance"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/svg/empty-card.svg"
            alt="Komerce"
            class="m-auto"
          >
          <div class="mt-2 text-[#626262]">
            Kamu Belum Mempunyai Kartu Komcards
          </div>
        </BOverlay>
      </BCard>
      <BCol
        v-else
        cols="12"
        md="12"
        class="p-0"
      >
        <VueSlickCarousel v-bind="settings">
          <CC
            v-for="card in data"
            :key="card.id"
            class="p-[5px]"
            :card="card"
            :class="card.status === 'pending' ? 'blur-sm' : ''"
            :source="'card_list'"
            :handle-action-topup="handleActionTopup"
            :handle-action-active-nonactive="handleActionActiveNonactive"
          />
        </VueSlickCarousel>
      </BCol>
    </BRow>

    <BModal
      id="modal-confirmation-active-inactive"
      ref="modal-confirmation-active-inactive"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md text-center">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="
              isVisibility
                ? '-webkit-text-security: disc'
                : '-webkit-text-security: none'
            "
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChange"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          v-if="role !== 'TALENT GLOBAL'"
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleClose"
          >
            Kembali
          </BButton>
          <BButton
            :variant="
              pin.length < 6 || loading.activeInactive ? 'secondary' : 'primary'
            "
            :disabled="pin.length < 6 || loading.activeInactive || loading.verifyPin"
            type="submit"
            @click.prevent="onActiveDeactive"
          >
            <BSpinner
              v-if="loading.activeInactive || loading.verifyPin"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <ModalMaxChancePIN />
  </BOverlay>
</template>

<script>
import { IDR } from '@/libs/currency'
import VueSlickCarousel from 'vue-slick-carousel'
import size from 'lodash/size'
import secureLs from '@/libs/secureLs'
import CodeInput from 'vue-verification-code-input'
import { newAxiosIns } from '@/libs/axios'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'
import { removeLocalStorageLogout } from '@/auth/utils'
import { settings } from '../config'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CC from './CC.vue'

export default {
  components: {
    VueSlickCarousel, CodeInput, CC, ModalMaxChancePIN,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loadingCard: {
      type: Boolean,
      default: false,
    },
    loadingPin: {
      type: Boolean,
      default: false,
    },
    loadingBalance: {
      type: Boolean,
      default: false,
    },
    handleTopup: {
      type: Function,
      default: () => {},
    },
    getListCards: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      IDR,
      settings,
      size,
      status: null,
      cardId: null,
      isVisibility: true,
      visibilityPin: 'number',
      errorMessage: '',
      pin: '',
      removeLocalStorageLogout,
      loading: {
        activeInactive: false,
        verifyPin: false,
      },
      role: secureLs.getItem('userData').role_name.toUpperCase(),
    }
  },
  methods: {
    async onActiveDeactive() {
      this.loading.verifyPin = true
      const url = '/auth/api/v1/user/secured/verify-pin'
      await newAxiosIns.post(url, { pin: this.pin })
        .then(async res => {
          const { data } = res.data
          this.loading.verifyPin = false
          if (data.attempt_left === 0) {
            this.errorMessage = 'PIN salah, kesempatan input PIN telah habis. Akun kamu akan terlogout secara otomatis.'
            this.removeLocalStorageLogout()
            window.location.replace('/')
          } if (!data.is_valid) {
            this.errorMessage = `Kamu memiliki ${data.attempt_left} percobaan lagi sebelum akun anda logout secara otomatis`
          } if (data.is_valid) {
            this.loading.activeInactive = true
            const activeUrl = `/komcards/api/v1/card/${this.cardId}/activated?pin=${this.pin}`
            const inactiveUrl = `/komcards/api/v1/card/${this.cardId}/deactivated?pin=${this.pin}`
            // eslint-disable-next-line no-shadow
            const url = this.status !== 'active' ? activeUrl : inactiveUrl

            await newAxiosIns
              .put(url)
              .then(() => {
                const activeMessage = 'Kartu Berhasil di Aktifkan'
                const inactiveMessage = 'Kartu Berhasil di Non-Aktifkan'
                const message = this.status !== 'active' ? activeMessage : inactiveMessage

                this.loading.activeInactive = false
                this.$bvModal.hide('modal-confirmation-active-inactive')
                this.$toast_success({ message })
                this.getListCards()
                this.errorMessage = ''
                this.pin = ''
              })
              .catch(err => {
                const { message } = err.response.data
                if (message === 'wrong pin numbers') {
                  this.errorMessage = 'PIN yang anda masukan salah'
                } else {
                  this.$toast_error({ message })
                }
                this.loading.activeInactive = false
              })
          }
        })
        .catch(() => {
          this.loading.verifyPin = false
          this.$bvModal.show('max-chance-PIN')
        })
    },
    handleChange(value) {
      this.pin = value
    },
    visiblePin() {
      this.isVisibility = !this.isVisibility
      this.visibilityPin = this.isVisibility ? 'number' : 'text'
    },
    handleClose() {
      this.$bvModal.hide('modal-confirmation-active-inactive')
      this.errorMessage = ''
      this.pin = ''
    },
    handleActionTopup(card) {
      this.handleTopup(card)
    },
    handleActionActiveNonactive({ id, status }) {
      this.$bvModal.show('modal-confirmation-active-inactive')
      this.status = status
      this.cardId = id
    },
  },
}
</script>
<style scoped lang="scss" src="../Komcard.scss"/>
