<template>
  <div>
    <BModal
      id="modal-topup"
      ref="modal-topup"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <strong class="text-black text-xl">
            {{ titleModal }}
          </strong>
        </BCol>
        <BCol class="d-flex justify-content-center my-1">
          <div class="text-black text-md">
            {{ subDescription }}
          </div>
        </BCol>
        <BCol class="justify-content-center">
          <ValidationObserver #default="{ invalid }">
            <BFormGroup>
              <template #label>
                <div
                  class="flex justify-between"
                >
                  <div class="text-black flex items-center">
                    <strong class="text-md font-semibold">Nominal</strong>
                    <strong class="text-primary">*</strong>
                  </div>
                  <div
                    v-if="modalType !== 'withdrawal'"
                    id="popover-topup"
                    class="text-[#0F679A] bg-[#DFF3FF]  rounded-[4px] p-[4px] flex gap-[4px] items-center"
                  >
                    <div class="text-[12px]">
                      Auto Top Up
                    </div>
                    <span class="k-info-circle-1 text-[15px]" />
                  </div>
                  <BPopover
                    triggers="hover"
                    target="popover-topup"
                    placement="top"
                  >
                    <div class="text-[#333333] text-[14px] font-semibold">
                      Auto Top-Up Komcards
                    </div>
                    <p class="mt-[7px] text-[12px]">
                      Nikmati kemudahan top-up otomatis ke Komcards, dan
                      <span
                        class="text-[#08A0F7] cursor-pointer"
                        @click="goToTopup"
                      >
                        Aktifkan Sekarang
                      </span>
                    </p>

                  </BPopover>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nominal"
                :rules="{ required: true, minAmount: modalType }"
              >
                <BFormInput
                  v-model="data.topup"
                  :placeholder="placeholder"
                  :state="errors.length > 0 ? false : null"
                  @keypress="isNumber($event)"
                  @input="formatCurrency"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <div>
                  <small
                    v-if="remainingBalances"
                    class="text-danger"
                  >
                    {{ remainingBalancesMessage }}
                  </small>
                </div>
                <div>
                  <small
                    v-if="messageNotEnoughBalance"
                    class="text-danger"
                  >
                    {{ messageNotEnoughBalance }}
                  </small>
                </div>
              </ValidationProvider>
              <BCol
                v-if="
                  limit_saldo_ideal &&
                    modalType !== 'withdrawal'
                "
                class="justify-between p-0 mt-[5px]"
              >
                <p class="mb-1 text-danger">
                  Maksimal Top Up : <b>{{ IDR(max_topup_balance) }}</b>
                </p>
                <BCol class="flex flex-row justify-between p-0 mb-[15px]">
                  <div class="text-black text-md">
                    {{ 'Saldo Kompay' }}
                  </div>
                  <div class="text-black text-md font-semibold">
                    {{ IDR(balanceData) }}
                  </div>
                </BCol>
                <div class="bg-[#FFEDED] p-1 rounded-lg">
                  <div class="d-flex mb-[8px]">
                    <span class="k-info-circle-1 text-danger font-bold align-content-center mr-[10px] text-xl" />
                    <div class="font-medium text-black text-[14px] align-content-center">
                      Batas Minimal Saldo Ideal : <b>Rp {{ dataBalanceAnalitycs.ideal_balance.toLocaleString('id-ID') }}</b>
                    </div>
                  </div>
                  <div class="mb-[8px]">
                    Saldo ideal diterapkan untuk menghindari saldo minus. <a
                      class="text-[#08A0F7] underline"
                      href="https://bantuan.komerce.id/id/article/saldo-ideal-komship-7dn9vp/?bust=1724821138075"
                      target="_blank"
                    >Pelajari lebih lanjut</a>
                  </div>
                  <div class="p-1 bg-[#FFFFFF]">
                    <div class="d-flex mb-[8px]">
                      <span class="k-message-question font-bold align-content-center mr-[10px] text-lg" />
                      <div class="font-medium align-content-center">
                        Darimana Saldo Ideal berasal ?
                      </div>
                    </div>
                    <span>Potensi Pendapatan Order Dikirim <b>Rp {{ dataBalanceAnalitycs.income_order_potential.toLocaleString('id-ID') }}</b> - Ongkir Retur Ketika Sampai <b>Rp {{ dataBalanceAnalitycs.ongkir_retur_on_finished.toLocaleString('id-ID') }}</b> - Resiko Ongkir Menjadi Retur <b>Rp {{ dataBalanceAnalitycs.ongkir_risk_become_retur.toLocaleString('id-ID') }}</b> =
                      Saldo Ideal <b class="text-danger">Rp {{ dataBalanceAnalitycs.ideal_balance.toLocaleString('id-ID') }}</b></span>
                  </div>
                </div>
              </BCol>
              <BCol
                v-if="modalType === 'withdrawal'"
                class="border rounded-lg p-1 my-2"
                style="border-color: #E2E2E2 !important"
              >
                <BPopover
                  triggers="hover"
                  target="popover-saldo-pending"
                  placement="topRight"
                >
                  Saldo ini belum dapat ditarik, namun bisa digunakan untuk
                  transaksi. Pihak penyedia layanan segera memprosesnya kembali
                  ke kartu debit kamu
                </BPopover>
                <div
                  class="flex items-center justify-between font-semibold text-black ml-1"
                >
                  <div>Saldo Komcards</div>
                  <div>{{ IDR(balanceDetail.monit_balance, 2, 2) }}</div>
                </div>
                <div class="flex items-center justify-between  text-black ml-2">
                  <div>Voucher Iklan</div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.voucher_balance, 2, 2) }}
                  </div>
                </div>
                <div class="flex items-center justify-between text-black ml-2">
                  <div class="flex items-center gap-3">
                    <div>Saldo Pending</div>
                    <img
                      id="popover-saldo-pending"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="Komerce"
                      width="20"
                      class="cursor-pointer"
                    >
                  </div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.hold_balance, 2, 2) }}
                  </div>
                </div>
                <div class="flex items-center justify-between  text-black ml-2">
                  <div>Saldo Mengendap</div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.balance_settle, 2, 2) }}
                  </div>
                </div>
                <div
                  class="flex items-center justify-between font-semibold text-black mt-1 border-[#FEF2CD] bg-[#FFF8E1] rounded-lg p-1"
                >
                  <div>Saldo yang bisa ditarik</div>
                  <div class="font-semibold">
                    {{ IDR(balanceDetail.effective_balance, 2, 2) }}
                  </div>
                </div>
              </BCol>
              <BCol
                v-if="modalType !== 'withdrawal' && !limit_saldo_ideal"
                class="flex flex-row justify-between p-0 mt-[5px]"
              >
                <div class="text-black text-md">
                  {{
                    modalType === 'withdrawal'
                      ? 'Saldo Komcards'
                      : 'Saldo Kompay'
                  }}
                </div>
                <div class="text-black text-md font-semibold">
                  {{
                    modalType === 'withdrawal'
                      ? IDR(balanceDetail.monit_balance)
                      : IDR(balanceData)
                  }}
                </div>
              </BCol>
            </BFormGroup>
            <BCol class="flex flex-row gap-4 justify-center mt-1">
              <BButton
                variant="outline-primary"
                @click="handleClose"
              >
                Kembali
              </BButton>
              <BButton
                :variant="
                  invalid ||
                    disabled ||
                    buttonSaldoIdeal ||
                    remainingBalances
                    ? 'secondary'
                    : 'primary'
                "
                type="submit"
                :disabled="
                  invalid ||
                    disabled ||
                    loading.possible ||
                    buttonSaldoIdeal ||
                    remainingBalances
                "
                @click="checkPossibility()"
              >
                <BSpinner
                  v-if="loading.possible"
                  variant="light"
                  small
                />
                {{ buttonText }}
              </BButton>
            </BCol>
          </ValidationObserver>
        </BCol>
      </BRow>
    </BModal>
    <ModalConfirmation
      :source="source"
      :payload-topup="payload"
      :card-data="cardData"
      :get-cards="getCards"
      :get-mutation="getMutation"
      @resetPayload="resetPayload"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { isNumber } from '@/libs/helpers'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { required } from '@validations'
import isNaN from 'lodash/isNaN'
import ModalConfirmation from './ModalConfirmation.vue'

extend('minAmount', {
  validate: (value, args) => {
    const { modalType } = args
    const minAmount = modalType === 'add' || modalType === 'topup' || modalType === null
      ? 20000
      : 10000
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= minAmount
  },
  params: ['modalType'],
  message: (value, valueContext) => {
    const { modalType } = valueContext
    const minAmount = modalType === 'add' || modalType === 'topup' || modalType === null
      ? 20000
      : 10000
    return `Minimal ${IDR(minAmount)}`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
  },
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    getCards: {
      type: Function,
      default: () => {},
    },
    getMutation: {
      type: Function,
      default: () => {},
    },
    balanceData: {
      type: Number,
      default: 0,
    },
    balanceDetail: {
      type: Object,
      default: () => ({}),
    },
    modalType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isNumber,
      required,
      isNaN,
      IDR,
      messageMaxTopup: null,
      user: this.$store.state.auth.userData,
      disabled: false,
      data: {
        topup: '',
        possible: {},
      },
      loading: {
        possible: false,
      },
      source: null,
      maxTopupMessage: '',
      remainingBalances: false,
      remainingBalancesMessage: null,
      messageNotEnoughBalance: null,
      dataBalanceAnalitycs: {},
      potential_last_balance: 0,
      max_topup_balance: 0,
      limit_saldo_ideal: false,
      buttonSaldoIdeal: false,
    }
  },
  computed: {
    balance() {
      return Number(this.data.topup.replace(/[^0-9]/g, ''))
    },
    payload() {
      return {
        card_id: this.cardData.id,
        balance: this.balance,
      }
    },
    remainingBalance() {
      return this.balanceData - this.balance
    },
    maxTopup() {
      return this.balanceData - this.data.possible.ideal_balance
    },
    titleModal() {
      return this.modalType === 'withdrawal'
        ? 'Penarikan Saldo Komcards'
        : 'Top Up Komcards'
    },
    buttonText() {
      return this.modalType === 'withdrawal' ? 'Tarik Saldo' : 'Top Up Sekarang'
    },
    balanceCard() {
      return this.modalType === 'withdrawal'
        ? this.balanceDetail.effective_balance
        : this.balanceData
    },
    placeholder() {
      return this.modalType === 'withdrawal'
        ? 'Minimal Rp. 10.000'
        : 'Minimal Top Up Rp 20.000'
    },
    subDescription() {
      return this.modalType === 'withdrawal'
        ? 'Saldo Komcards yang kamu tarik akan masuk ke saldo Kompay'
        : `${this.cardData.label} (${this.cardData.card_number})`
    },
  },
  methods: {
    goToTopup() {
      const route = this.$router.resolve({ name: 'payment-setting' })
      window.open(route.href, '_blank')
    },
    async checkPossibility() {
      const remainingBalance = this.balanceDetail - this.balance
      if (this.modalType === 'withdrawal') {
        if (remainingBalance < 10000) {
          this.remainingBalancesMessage = `Sisakan saldo minimal ${IDR(
            10000,
          )} di kartu Anda`
          this.remainingBalances = true
          return
        }
        this.$bvModal.show('modal-confirmation')
        this.source = 'withdrawal'
      } else {
        if (
          this.user.is_komcards
          && !this.user.is_komship
          && !this.user.is_kompack
        ) {
          this.$bvModal.show('modal-confirmation')
          this.source = 'topup'
          return
        }

        if (
          (this.user.is_komcards
          && this.user.is_komship)
          || !this.user.is_kompack
        ) {
          this.loading.possible = true
          this.source = 'topup'
          const partner_id = this.user.partner_detail.id
          const url = `/komship/api/v1/admin/partner-consumables/balance-analitycs/${partner_id}`

          await newAxiosIns.get(url).then(res => {
            const { data } = res.data
            this.dataBalanceAnalitycs = data
            this.potential_last_balance = this.balanceData - this.balance
            this.max_topup_balance = Math.floor(this.balanceData - data.ideal_balance)
            this.limit_saldo_ideal = false
            this.buttonSaldoIdeal = true
            if (this.potential_last_balance >= data.ideal_balance) {
              this.$bvModal.show('modal-confirmation')
              this.buttonSaldoIdeal = false
            } else {
              this.limit_saldo_ideal = true
              this.buttonSaldoIdeal = true
            }
            this.loading.possible = false
          })
        } else {
          this.$bvModal.show('modal-confirmation')
          this.loading.possible = false
          this.source = 'topup'
        }

        // if (this.user.is_kompack) {
        //   this.$bvModal.show('modal-confirmation')
        //   this.loading.possible = false

        //   this.source = 'topup'
        // }
      }
    },
    handleClose() {
      this.$bvModal.hide('modal-topup')
      this.data.topup = ''
      this.limit_saldo_ideal = false
      this.buttonSaldoIdeal = false
      this.remainingBalances = false
      this.remainingBalancesMessage = null
      this.messageNotEnoughBalance = null
    },
    formatCurrency(value) {
      const newValue = Number(value.replace(/\D/g, ''))
      this.data.topup = isNaN(newValue) ? value : IDR(newValue)
      if (newValue > this.balanceCard) {
        this.messageNotEnoughBalance = 'Maaf saldo anda tidak mencukupi'
        this.disabled = true
        this.remainingBalances = false
        this.buttonSaldoIdeal = false
      } else {
        this.messageNotEnoughBalance = null
        this.disabled = false
        this.remainingBalances = false
        this.buttonSaldoIdeal = false
      }
    },
    resetPayload() {
      this.data.topup = ''
    },
  },
}
</script>
