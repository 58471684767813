<template>
  <BOverlay
    :show="loading.mutation"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BTable
      :id="scroll"
      :items="items"
      :fields="fields"
      show-empty
      empty-text="Belum melakukan transaksi"
      responsive
      class="mt-1"
      hover
    >
      <template #cell(description)="data">
        <div class="flex flex-column gap-[5px]">
          <span>{{ data.item.description }}</span>
          <span v-if="!data.item.is_kompoints" class="text-[#828282]">{{ data.item.transaction_uuid }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div :class="statusClass(data.item.status)">
          <div
            v-if="data.item.status === 'success'"
            class="flex items-center justify-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/tick-circle.svg"
              alt="Komerce"
              width="20"
            >
            <div class="ml-[5px]">
              Berhasil
            </div>
          </div>
          <div
            v-if="data.item.status === 'failed'"
            class="flex items-center justify-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
              alt="Komerce"
              width="20"
            >
            <div class="ml-[5px]">
              Gagal
            </div>
          </div>
          <div
            v-if="data.item.status === 'pending'"
            class="flex items-center justify-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/info-circle.svg"
              alt="Komerce"
              width="20"
            >
            <div class="ml-[5px]">
              Pending
            </div>
          </div>
        </div>
      </template>
      <template #cell(date)="data">
        {{ FULL_DATE_TIME(data.item.date) }}
      </template>
      <template #cell(card)="data">
        <div class="flex items-center justify-start">
          <BImg
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-pos.svg"
          />
          <div class="ml-[5px]">
            {{ data.item.card.card_label }}
          </div>
        </div>
      </template>
      <template #cell(amount)="data">
        <div
          v-if="data.item.is_kompoints"
          class="flex justify-center items-center gap-3"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
            alt="Komerce"
            width="20"
          >
          <div>{{ data.item.amount }}</div>
        </div>
        <div
          v-else
          :class="nominalClass(data.item.transaction_type)"
        >
          <span v-if="data.item.transaction_type === 'credit'">+{{ IDR(data.item.amount,2,2) }}</span>
          <div v-if="data.item.transaction_type === 'debit'">
            -{{ IDR(data.item.amount,2,2) }}
          </div>
          <div
            v-if="data.item.transaction_type === 'cashback'"
            class="flex items-center justify-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
              alt="Komerce"
              width="20"
            >
            <div class="ml-[5px]">
              {{ IDR(data.item.amount,2,2) }}
            </div>
          </div>
        </div>
      </template>
    </BTable>
  </BOverlay>
</template>

<script>
import { FULL_DATE_TIME } from '@/libs/formatDate'
import { IDR } from '@/libs/currency'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    statusClass: {
      type: Function,
      default: () => () => '',
    },
    nominalClass: {
      type: Function,
      default: () => () => '',
    },
    scroll: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FULL_DATE_TIME,
      IDR,
    }
  },
}
</script>
